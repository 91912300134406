import Bowser from 'bowser';
import { InvoiceEstimate } from '../../types/dapi/InvoiceEstimates';

export const isInternetExplorer = () => {
  if (typeof window !== 'undefined') {
    const browser = Bowser.detect(window.navigator.userAgent);
    return browser.name === 'Internet Explorer';
  }

  return false;
};

/**
 * Analyzes an invoice estimate to determine its type and amount
 * @param {InvoiceEstimate | undefined} invoiceEstimate - The invoice estimate object to analyze
 * @returns {Object} An object containing:
 *   - hasEstimate: Whether a valid in-network estimate exists
 *   - hasCopayEstimate: Whether the estimate is a copay (estimate equals copay amount)
 *   - hasClearpayEstimate: Whether the estimate is a clearpay estimate (estimate differs from copay)
 *   - estimateAmount: The in-network estimate amount
 */
export const determineEstimateTypeAndAmount = (invoiceEstimate: InvoiceEstimate) => {
  const hasEstimate =
    invoiceEstimate.estimate_in_network != null &&
    typeof invoiceEstimate.estimate_in_network === 'number';

  const hasClearpayEstimate =
    hasEstimate && invoiceEstimate.estimate_in_network !== invoiceEstimate.copay_in_network;

  const hasCopayEstimate =
    hasEstimate &&
    invoiceEstimate.copay_in_network != null &&
    invoiceEstimate.copay_in_network === invoiceEstimate.estimate_in_network;

  const amount = invoiceEstimate.estimate_in_network!;

  return {
    hasEstimate,
    hasCopayEstimate,
    hasClearpayEstimate,
    amount,
  };
};
