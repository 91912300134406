// import * as Tooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import {
  CreditCardIcon,
  Inbox,
  LockIcon,
  MegaphoneIcon,
  SendToBackIcon,
  WaypointsIcon,
} from 'lucide-react';
import { useState } from 'react';
import { HELP_CENTER_URL } from '../../constants/url';
import { shouldShowFeature } from '../../core/viewCustomization';
import { FeatureName } from '../../core/viewCustomization/featureNames';
import { useLogin } from '../../hooks/useLogin';
import { RemixRoutes } from '../../routes/remixRoutes';
import { useUiConfig } from '../UiConfigContext';
import { AnimatedSolvLogo } from './AnimatedSolvLogo';
import CallCenter from './icons/CallCenter';
import Messaging from './icons/Communications';
import Help from './icons/Help';
import HideNavigation from './icons/HideNavigation';
import Patients from './icons/Patients';
import Queue from './icons/Queue';
import Reports from './icons/Reports';
import Reviews from './icons/Reviews';
import Settings from './icons/Settings';
import ShowNavigation from './icons/ShowNavigation';
import { SideNavLink } from './SideNavLink';
import { UserDropdown } from './UserDropdown';
import { getBooleanFlag, useBooleanFlag } from '../../core/flags/flags';
import { InboxOnboarding } from '../Inbox/InboxOnboarding';

export function SideNav({ collapsed = false }: { collapsed?: boolean }) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const { setUiConfig, uiConfig } = useUiConfig();
  const login = useLogin();
  const inboxOptInFlag = useBooleanFlag('msg-inbox-opt-in') === true;
  const showInbox =
    (uiConfig.useNewInbox === true && inboxOptInFlag) || getBooleanFlag('msg-inbox-release');

  return (
    <div
      className={clsx(
        'px-2 py-8 flex flex-col justify-between h-[100dvh] overflow-y-auto overflow-x-hidden',
        'border-r border-gray-200 font-sans antialiased tw-reset flex-shrink-0',
        isCollapsed ? 'w-[66px]' : 'w-56',
        'transition-all duration-300 group'
      )}
    >
      <div className="space-y-8">
        <div className="h-12 relative flex flex-col">
          <div className="flex w-full justify-between items-start">
            <AnimatedSolvLogo isCollapsed={isCollapsed} />
          </div>
        </div>

        <div className={clsx('transition-all duration-300', isCollapsed ? 'mx-0' : 'mx-0')}>
          <SideNavLink
            href="/queue"
            icon={Queue}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.QUEUE_TAB)}
          >
            Queue
          </SideNavLink>
          {showInbox && (
            <div className="relative">
              <InboxOnboarding align="right">
                <SideNavLink
                  href={RemixRoutes.inbox}
                  icon={Inbox}
                  collapsed={isCollapsed}
                  gate={shouldShowFeature(login, FeatureName.QUEUE_TAB)}
                >
                  Inbox
                </SideNavLink>
              </InboxOnboarding>
            </div>
          )}
          <SideNavLink
            href="/patients"
            icon={Patients}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.PATIENTS_TAB)}
          >
            Patients
          </SideNavLink>
          <SideNavLink
            href={RemixRoutes.payments}
            external
            icon={CreditCardIcon}
            collapsed={isCollapsed}
            matchPath="/payments"
            gate={shouldShowFeature(login, FeatureName.PAYMENTS_TAB)}
          >
            Payments
          </SideNavLink>
          <SideNavLink
            href={RemixRoutes.messaging}
            external
            icon={MegaphoneIcon}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.MESSAGING_TAB)}
          >
            Messaging
          </SideNavLink>
          <SideNavLink
            href="/reports"
            icon={Reports}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.REPORTS_TAB)}
          >
            Reports
          </SideNavLink>
          <SideNavLink
            href="/connect"
            icon={WaypointsIcon}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.CONNECT_TAB)}
          >
            Connect
          </SideNavLink>
          <SideNavLink
            href={RemixRoutes.reviews}
            external
            icon={Reviews}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.REPORTS_REVIEWS_TAB)}
          >
            Reviews
          </SideNavLink>
          <SideNavLink
            href="/call-center"
            icon={CallCenter}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.CALL_CENTER)}
          >
            Call center
          </SideNavLink>
          <SideNavLink
            href={RemixRoutes.adminTools}
            external
            icon={LockIcon}
            collapsed={isCollapsed}
            gate={shouldShowFeature(login, FeatureName.ADMIN_TOOLS)}
          >
            Admin tools
          </SideNavLink>
        </div>
      </div>

      <div className="flex flex-col items-stretch gap-6">
        <div className="border-t border-gray-100 pt-8 transition-all duration-300 flex flex-col items-stretch">
          <SideNavLink
            onClick={() => setUiConfig({ useNewNav: false })}
            icon={SendToBackIcon}
            collapsed={isCollapsed}
          >
            Old navigation
          </SideNavLink>
          <SideNavLink
            href="/settings"
            matchPath="/settings"
            icon={Settings}
            collapsed={isCollapsed}
          >
            Settings
          </SideNavLink>
          <SideNavLink href={HELP_CENTER_URL} external icon={Help} collapsed={isCollapsed}>
            Help
          </SideNavLink>
          <SideNavLink
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? ShowNavigation : HideNavigation}
            collapsed={isCollapsed}
            tooltipLabel="Expand"
          >
            Collapse
          </SideNavLink>
        </div>

        <UserDropdown isCollapsed={isCollapsed} />
      </div>
    </div>
  );
}
