import React, { CSSProperties, useEffect } from 'react';
import moment from 'moment';
import Head from 'next/head';
import { ConfigProvider, Row } from 'antd';
import { NewVersionNotification } from '../NewVersion/NewVersionNotification';
import { useReduxMigrate } from '../../store/useReduxMigrate';
import { AuthProvider, AuthProviderProps } from './AuthProvider';
import { FullPageSpinner } from './FullPageSpinner';
import { IntercomChat } from '../Intercom/IntercomChat';
import TopNav from '../TopNav';
import styled from 'styled-components';
import { fontFamily } from '../../constants/text';
import { breakpoints } from '../primitives/constants';
import { IeWarning } from '../IeWarning/IeWarning';
import { SideNav } from '../SideNav/SideNav';
import clsx from 'clsx';
import { useUiConfig } from '../UiConfigContext';
import UserFeedbackButton from '../Feedback/UserFeedbackButton';
import useSentrySetUser from '../../hooks/useSentrySetUser';
import { useBooleanFlag } from '../../core/flags/flags';

moment.locale('en');

const TopNavTitle = styled(Row)`
  font-family: ${fontFamily.medium};
  font-size: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Container = styled.div`
  width: 90%;
  overflow: hidden;
  max-width: 1600px;
  margin: auto;
  @media screen and (max-width: ${breakpoints.tablet}px) {
    width: 95%;
  }
`;

export type LayoutProps = AuthProviderProps & {
  auth?: boolean;
  children?: any;
  error?: any;
  title: string;
  loading?: boolean;
  hideIntercom?: boolean;
  hideFeedbackWidget?: boolean;
  style?: CSSProperties;
  fullWidth?: boolean;
  hideTitle?: boolean;
  route?: string;
  hideNav?: boolean;
  fullNav?: boolean;
  className?: string;
};

export default function Layout(props: LayoutProps) {
  useReduxMigrate();
  useSentrySetUser();

  const { uiConfig } = useUiConfig();
  const isFeedbackEnabled = useBooleanFlag('sentry-feedback-widget');

  if (props.error) {
    return props.children;
  }

  return (
    <div
      className={clsx('layout relative h-[100dvh] overflow-hidden', props.className)}
      style={{
        ...props.style,
      }}
    >
      <Wrapper {...props}>
        {/* Used to block manage usage on internet explorer: https://solvhealth.atlassian.net/browse/AB-594 */}
        <IeWarning />
        {<IntercomChat hide={!!props.hideIntercom || !props.auth} />}
        {props.auth && isFeedbackEnabled && !props.hideFeedbackWidget && <UserFeedbackButton />}
        <NewVersionNotification />
        <Head>
          <title>{props.title}</title>
        </Head>
        <ConfigProvider locale={undefined}>
          {/*{isDev() && <DebugBreakpointViewer />}*/}
          {props.loading ? (
            <FullPageSpinner />
          ) : (
            <>
              <div className="flex h-full items-stretch">
                {!props.hideNav && uiConfig.useNewNav && (
                  <div className="relative">
                    <SideNav collapsed={!props.fullNav} />
                  </div>
                )}
                <div className="overflow-y-auto w-full flex-1" id="layout-scroll-container">
                  {!props.hideNav && !uiConfig.useNewNav && (
                    <div className="hide-when-printing">
                      <TopNav route={props.route} showTitle={!props.hideTitle} />
                    </div>
                  )}
                  {props.fullWidth ? (
                    <>
                      {!props.hideTitle && <TopNavTitle>{props.title}</TopNavTitle>}
                      {props.children}
                    </>
                  ) : (
                    <Container>
                      {!props.hideTitle && <TopNavTitle>{props.title}</TopNavTitle>}
                      {props.children}
                    </Container>
                  )}
                </div>
              </div>
            </>
          )}
        </ConfigProvider>
      </Wrapper>
    </div>
  );
}

const Wrapper = (props: Partial<LayoutProps>) => {
  if (props.auth) {
    return <AuthProvider {...props}>{props.children}</AuthProvider>;
  }
  return props.children;
};
