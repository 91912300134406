import { useSelector } from '../../../types/Redux';

export function useQueueState() {
  const currentTab = useSelector((s) => s.queue.currentTab);
  const selectedLocationIds = useSelector((s) => s.queue.selectedLocationIds);

  return {
    currentTab,
    selectedLocationIds,
  };
}
