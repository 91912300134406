import { Icon, Popover } from 'antd';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';
import { useDemoData } from '../../core/dapi/demoData';
import { getAccountHolderName } from '../../core/util/clinicAccounts';
import { useLogin } from '../../hooks/useLogin';
import { useQueueState } from '../Queue/hooks/useQueueState';
import { Avatar } from './Avatar';
import { NavUi } from './constants';

const menuButtonStyles = `text-xs flex items-center justify-between w-full hover:!text-pink-600 transition-colors`;

export function UserDropdown({ isCollapsed }: { isCollapsed: boolean }) {
  const login = useLogin();
  const name = getAccountHolderName(login.account);
  const { selectedLocationIds } = useQueueState();

  const buttonWidth = isCollapsed ? NavUi.COLLAPSED_NAV_WIDTH - 16 : NavUi.EXPANDED_NAV_WIDTH - 16;

  const [visible, setVisible] = useState(false);
  const { showDemoActions, clear, reset, dischargeBookings } = useDemoData({
    onComplete: () => setVisible(false),
  });

  return (
    <Popover
      placement={isCollapsed ? 'right' : 'top'}
      autoAdjustOverflow
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <div className="text-left tw-reset text-gray-900 font-sans space-y-4 ">
          <div>
            <p className="text-sm font-semibold">{name}</p>
            <p className="text-xs text-gray-500">{login?.account?.email}</p>
          </div>

          <div>
            <Link href="/log-out">
              <a className={menuButtonStyles}>
                Log out <Icon type="logout" />
              </a>
            </Link>
          </div>

          {showDemoActions && (
            <>
              <div>
                <button onClick={clear} className={menuButtonStyles}>
                  Clear Demo Data
                </button>
              </div>

              <div>
                <button onClick={reset} className={menuButtonStyles}>
                  Reset Demo Data
                </button>
              </div>

              <div>
                <button onClick={dischargeBookings} className={menuButtonStyles}>
                  Discharge demo bookings
                </button>
              </div>
            </>
          )}
        </div>
      }
    >
      <motion.button
        initial={false}
        className="rounded-full w-full border border-gray-200  flex items-center gap-3 text-left hover:bg-gray-50 transition-colors cursor-pointer !p-1"
        animate={{
          width: buttonWidth,
        }}
        transition={{ duration: 0.3 }}
      >
        <Avatar name={name} size="10" />
        <p className="text-sm font-semibold whitespace-nowrap overflow-hidden text-gray-900">
          {name}
        </p>
      </motion.button>
    </Popover>
  );
}
