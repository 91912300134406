import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import useAccount from './useAccount';

export default function useSentrySetUser() {
  const account = useAccount();

  useEffect(() => {
    const sentryUser = Sentry.getCurrentScope().getUser();
    const isCurrentUserSet = sentryUser?.id === account?.id;

    if (account && !isCurrentUserSet) {
      Sentry.setUser({
        email: account.email,
        id: account.id,
        username: account.username,
      });
    }
  }, [account]);
}
