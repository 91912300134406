import React from 'react';
import * as Sentry from '@sentry/nextjs';
import styled from 'styled-components';
import { Volume2 } from 'lucide-react';

export default function UserFeedbackButton() {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    const feedback = Sentry.feedbackIntegration(FEEDBACK_OPTIONS);
    if (feedback && buttonRef.current) {
      feedback.attachTo(buttonRef.current);
    }

    return () => {
      if (feedback) {
        feedback.remove();
      }
    };
  }, []);

  if (!Sentry.isInitialized()) {
    return null;
  }

  return (
    <FeedbackButtonContainer id="feedback-btn-container">
      {/* Injecting custom styles to the feedback widget shadow DOM */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
                  #sentry-feedback {
                    --bottom: 78px;
                    --submit-background: #e30c79;
                    --submit-background-hover: #d30b6f;
                    --submit-border: #bb2b70;
                  }
                `,
        }}
      />
      <FeedbackButton ref={buttonRef}>
        <Volume2 size={25} />
      </FeedbackButton>
    </FeedbackButtonContainer>
  );
}

const FEEDBACK_OPTIONS = {
  colorScheme: 'light' as const,
  showBranding: false,
  buttonLabel: 'Give Feedback',
  submitButtonLabel: 'Send Feedback',
  messagePlaceholder: '',
  formTitle: 'Give Feedback',
  successMessageText: 'Thank you for your feedback!',
} as const;

const FeedbackButtonContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1005;
`;

const FeedbackButton = styled.button`
  background-color: #e30c79;
  color: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.1s ease-in-out;
  :hover {
    transform: scale(1.1) rotate(-12deg);
  }
`;
