import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalEvent, useInterval, useTimeout } from 'beautiful-react-hooks';
import { HttpError, httpPostJson } from '../../core/http/http';
import { DapiSingleResult } from '../../core/dapi/response';
import { LoginToken, RefreshTokenResponse, RootState } from '../../types/RootState';
import { getLoginUrl } from '../../core/dapi/login';
import { getAuth, removeAuth, setAuth } from '../../core/auth';
import { DAPI_CLIENT_ID, DAPI_CLIENT_SECRET } from '../../config';
import { FullPageSpinner } from './FullPageSpinner';
import { retryAsync } from '../../core/util/retry';
import { log } from '../../core/logger/log';
import { useRouter } from 'next/router';
import { clearPersistedStore, store } from '../../store/configureStore';
import { onLoginSuccess } from '../../ducks/login';
import { useDispatch } from '../../types/Redux';
import { identifyUserForFlags } from '../../core/flags/flags';
import { analyticsTrackEvent } from '../../core/analytics';
import { useGlobalLogoutEvent } from '../../core/auth/global';
import { MAX_SESSION_AGE } from '../../pages/api/auth/store-login';

const buildLoginRefresh = (refreshToken: string) => ({
  client_id: DAPI_CLIENT_ID,
  client_secret: DAPI_CLIENT_SECRET,
  grant_type: 'refresh_token',
  refresh_token: refreshToken,
});

const NEXT_REFRESH_TIME = 30 * 60 * 1000;

enum ValidateTokenResult {
  Success,
  AccountNotSet,
  AccountIdNotMatchToken,
  InvalidToken,
}

const renewDapiToken = async (): Promise<RefreshTokenResponse> => {
  /*
    Sometimes this can fail due to race conditions if the token expires as your refreshing it, so retrying or good measure
   */
  return retryAsync(async () => {
    // Grabbing fresh everytime from storage, just in case the passed in version is stale
    const auth = getAuth();
    if (!auth) {
      return;
    }
    console.debug('refreshing dapi auth token');
    const postData = buildLoginRefresh(auth.refresh_token);
    const result = await httpPostJson<DapiSingleResult<RefreshTokenResponse>>(
      getLoginUrl(),
      postData,
      {
        ignoreAuth: true,
      }
    );
    await setAuth(
      {
        ...result.data,
        clinic_account_id: auth.clinic_account_id,
        meta: { last_refresh: Date.now() },
        token_version: result.data.token_version ?? 1,
      },
      {
        // Don't want to clear cache when refreshing, as this happens fairly often
        clearRemixCache: false,
      }
    );
    return result.data;
  }, 3);
};

/*
  Run some basic checks to ensure the auth token matches that account we have stored in redux
 */
const validateToken = (auth: LoginToken): ValidateTokenResult => {
  const state = store.getState() as unknown as RootState;

  if (!state.login || !state.login?.account?.id) {
    return ValidateTokenResult.AccountNotSet;
  }

  if (state.login.account.id !== auth.clinic_account_id) {
    return ValidateTokenResult.AccountIdNotMatchToken;
  }

  if (!Boolean(auth.clinic_account_id && auth.access_token && auth.refresh_token)) {
    return ValidateTokenResult.InvalidToken;
  }

  return ValidateTokenResult.Success;
};

export type AuthProviderProps = {
  allowRedirectBackAfterAuth?: boolean;
};

export function AuthProvider({
  children,
  allowRedirectBackAfterAuth,
}: { children: any } & AuthProviderProps) {
  const [step, setStep] = useState<'loading' | 'failed' | 'success'>('loading');

  const router = useRouter();
  const dispatch = useDispatch();

  const redirectQs = useMemo(() => {
    return allowRedirectBackAfterAuth ? `?redirect_url=${btoa(router.asPath)}` : '';
  }, [allowRedirectBackAfterAuth, router.asPath]);

  const refreshTimeout = useRef<NodeJS.Timeout | null>(null);

  const onTokenValid = (token: RefreshTokenResponse) => {
    const state = store.getState() as unknown as RootState;
    const account = state.login.account;

    if (token.token_version && token.token_version > 1) {
      // Refresh the token when it expires next
      if (refreshTimeout.current) clearTimeout(refreshTimeout.current);
      refreshTimeout.current = setTimeout(() => {
        if (['/log-out', '/login'].includes(router.pathname)) return;
        console.debug('token expiration timer elapsed', { expires_in: token.expires_in });
        renewDapiToken().then(onTokenValid).catch(onRefreshFailure);
      }, token.expires_in * 1000);
    }

    identifyUserForFlags(account).finally(() => {
      setStep('success');
    });
  };

  const onRefreshFailure = (error: any) => {
    console.error('Failed to refresh dapi token', error);
    if (refreshTimeout.current) clearTimeout(refreshTimeout.current);
    setStep('failed');
  };

  useGlobalLogoutEvent(() => {
    if (refreshTimeout.current) clearTimeout(refreshTimeout.current);
    // dapi auth key was cleared, probably another tab logging out, force redirect to login
    router.push(`/login${redirectQs}`);
  });

  useEffect(() => {
    /*
    If no auth or auth is invalid, kick them to login screen
     */
    const auth = getAuth();

    if (!auth?.clinic_account_id) {
      if (refreshTimeout.current) clearTimeout(refreshTimeout.current);
      router.push(`/login${redirectQs}`);
      return;
    }

    const validate = validateToken(auth);

    if (
      validate === ValidateTokenResult.AccountIdNotMatchToken ||
      validate === ValidateTokenResult.InvalidToken
    ) {
      if (refreshTimeout.current) clearTimeout(refreshTimeout.current);
      router.push(`/login${redirectQs}`);
      return;
    }

    /*
      They have the token in local storage, but the account is not in redux state.
      This likely happens if they open a new tab up because redux state is in session storage, which isn't shared.
      Let's just run them through the onLoginSuccess to populate the store and redirect them
     */
    if (validate === ValidateTokenResult.AccountNotSet) {
      onLoginSuccess(dispatch, auth, {
        redirectUrl: router.asPath,
        clearRemixCache: false,
      })
        .then(() => {
          onTokenValid(auth);
        })
        .catch((err) => {
          if (err instanceof HttpError) {
            const status = err.getStatusCode();
            // Token must be expired, lets try to refresh it
            if (status === 401) {
              renewDapiToken()
                .then(() => {
                  const auth = getAuth();
                  if (auth) {
                    // Try to do onLoginSuccess again so the redux store gets populated
                    onLoginSuccess(dispatch, auth)
                      .then(() => onTokenValid(auth))
                      .catch(() => setStep('failed'));
                  }
                })
                // Failed to renew, token must be messed up
                .catch(onRefreshFailure);
            }
          } else {
            setStep('failed');
          }
        });
      return;
    }

    if (auth.token_version && auth.token_version > 1) {
      // Get the server time in case the client clock is off
      fetch('/api/auth/clock')
        .then((res) => res.json())
        .then((data) => {
          const serverTime = new Date(data);
          const tokenExpiration = new Date(auth.expiration_timestamp);
          const expiresIn = tokenExpiration.getTime() - serverTime.getTime();
          const cookieCreated = new Date(auth.meta.last_refresh);
          const oneHourAgo = new Date(serverTime.getTime() - 60 * 60 * 1000);

          console.debug('loading stored token', { serverTime, tokenExpiration, expiresIn });

          // Tokens can be refreshed up to 5 minutes before they expire
          // Wait until 2.5 minutes to mitigate clock drift
          if (expiresIn <= 2.5 * 60 * 1000) {
            // Token is expired, refresh it
            console.debug('token expired', { tokenExpiration });
            renewDapiToken().then(onTokenValid).catch(onRefreshFailure);
          } else if (cookieCreated <= oneHourAgo) {
            // Cookie is getting old, set a new one
            console.debug('auth cookie over age threshold', { cookieCreated });
            onTokenValid(auth);
          } else {
            onTokenValid(auth);
          }
        });
    } else {
      const lastRefresh = auth.meta?.last_refresh;
      if (lastRefresh) {
        const diff = Date.now() - lastRefresh;
        const minutesLeft = Math.round((NEXT_REFRESH_TIME - diff) / 60 / 1000);
        if (minutesLeft > 0) {
          console.debug(
            `already refreshed recently, not refreshing again for ${minutesLeft} minutes.`
          );
          onTokenValid(auth);
          return;
        }
      }

      renewDapiToken()
        .then(() => {
          onTokenValid(auth);
        })
        .catch(onRefreshFailure);
    }
  }, [allowRedirectBackAfterAuth, dispatch, redirectQs, router]);

  useInterval(async () => {
    const auth = getAuth();
    if (auth?.token_version && auth.token_version > 1) return;
    try {
      await renewDapiToken();
    } catch (e) {
      onRefreshFailure(e);
    }
  }, NEXT_REFRESH_TIME);

  useEffect(() => {
    if (step === 'failed') {
      analyticsTrackEvent('AUTH_PROVIDER_AUTHENTICATION_FAILED', {
        clinicAccountId: getAuth()?.clinic_account_id ?? '',
      });
      // Just do a full reload because something is messed up.
      clearPersistedStore();
      removeAuth();
      window.location.replace(`/login${redirectQs}`);
    }
  }, [redirectQs, router, step]);

  if (step === 'loading' || step === 'failed') {
    return <FullPageSpinner />;
  }

  return children;
}
