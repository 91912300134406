import { Popover } from 'antd';
import { useUiConfig } from '../UiConfigContext';

export function InboxOnboarding(props: { align: 'right' | 'bottom'; children: React.ReactNode }) {
  const { uiConfig, setUiConfig } = useUiConfig();
  return (
    <>
      <Popover
        content={
          <div className="max-w-64">
            <div className="font-semibold pb-1">Access the inbox from anywhere</div>
            <div className="text-gray-600 pb-2">
              The updated inbox is now located on the main navigation bar so you can access the
              inbox no matter where you are.
            </div>
            <div className="flex justify-end pt-2">
              <button
                className="bg-blue-600 font-medium text-white px-4 py-2 rounded-md cursor-pointer"
                onClick={() => setUiConfig({ newInboxOnboarded: true })}
              >
                Done
              </button>
            </div>
          </div>
        }
        visible={!uiConfig.newInboxOnboarded}
        placement={props.align}
        trigger="click"
      >
        {props.children}
      </Popover>
    </>
  );
}
