import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'sonner';
import { apiPostJson } from '.';
import { useQueueState } from '../../components/Queue/hooks/useQueueState';
import { DAPI_HOST, isDev } from '../../config';
import { useLogin } from '../../hooks/useLogin';
import { isSolvEmployeeAccount } from '../session';
import { isTestLocation } from '../util/location';
import { AccountLocation } from '../../types/RootState';

export const getClearDemoDataUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/demo-data/${locationId}/clear`;

export const getResetDemoDataUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/demo-data/${locationId}/reset`;

export const getDischargeDemoBookingsUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/demo-data/${locationId}/discharge-bookings`;

// note: when updating this list, you must update the dapi whitelist as well
const DEMO_DATA_TOOLS_GROUPS = ['LG6xdp'];
export const isDemoDataToolsGroup = (location: AccountLocation) => {
  return DEMO_DATA_TOOLS_GROUPS.some((id) => location.group_ids?.includes(id));
};

export function useDemoData({ onComplete }: { onComplete?: () => void } = {}) {
  const login = useLogin();
  const { selectedLocationIds } = useQueueState();
  const queryClient = useQueryClient();

  const showDemoActions =
    isSolvEmployeeAccount(login) &&
    selectedLocationIds.length === 1 &&
    selectedLocationIds.some((selectedId) => {
      const selectedLoc = login.account.locations.find(
        (accLoc) => accLoc.location_id === selectedId
      );
      return selectedLoc && (isTestLocation(selectedLoc) || isDemoDataToolsGroup(selectedLoc));
    });

  const clearDemoDataMutation = useMutation((locationId: string) =>
    apiPostJson(getClearDemoDataUrl(locationId), {})
  );

  const resetDemoDataMutation = useMutation((locationId: string) =>
    apiPostJson(getResetDemoDataUrl(locationId), {})
  );

  const dischargeDemoBookingsMutation = useMutation((locationId: string) =>
    apiPostJson(getDischargeDemoBookingsUrl(locationId), {})
  );

  function clear() {
    if (!selectedLocationIds[0]) {
      toast.error('No location selected');
      return;
    }

    toast.loading('Clearing demo data');
    clearDemoDataMutation.mutate(selectedLocationIds[0], {
      onSuccess: () => {
        if (!isDev()) {
          window.location.reload();
        } else {
          queryClient.invalidateQueries();
        }
        onComplete?.();
        toast.dismiss();
        toast.success('Demo data is cleared, refreshing page');
      },
    });
  }

  function reset() {
    if (!selectedLocationIds[0]) {
      toast.error('No location selected');
      return;
    }

    toast.loading('Resetting demo data');
    resetDemoDataMutation.mutate(selectedLocationIds[0], {
      onSuccess: () => {
        if (!isDev()) {
          window.location.reload();
        } else {
          queryClient.invalidateQueries();
        }
        onComplete?.();
        toast.dismiss();
        toast.success('Demo data reset, refreshing page');
      },
    });
  }

  function dischargeBookings() {
    if (!selectedLocationIds[0]) {
      toast.error('No location selected');
      return;
    }

    toast.loading('Discharging demo bookings');
    dischargeDemoBookingsMutation.mutate(selectedLocationIds[0], {
      onSuccess: () => {
        if (!isDev()) {
          window.location.reload();
        } else {
          queryClient.invalidateQueries();
        }
        onComplete?.();
        toast.dismiss();
        toast.success('Demo bookings discharged, refreshing page');
      },
    });
  }

  return { showDemoActions, clear, reset, dischargeBookings };
}
